import React from "react";

type Props = {
    blogImage: string;
    title: string;
};

const BlogCard = ({ blogImage, title }: Props) => {
    return (
        <div>
            <div className="bg-black flex flex-col gap-5 border-[1px] border-[#33302F] rounded-xl transition ease-in-out delay-300 hover:-translate-y-1 hover:scale-110">
                <img src={blogImage} alt="" className="w-[100%] h-[180px] rounded-xl" />
                <div className="text-white font-bold text-[20px] p-5"> {title}</div>
            </div>
        </div>
    );
};

export default BlogCard;
