import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { IconType } from "react-icons";
import { GoProjectSymlink } from "react-icons/go";
import { IoChatbubbleOutline } from "react-icons/io5";
import { RxCounterClockwiseClock } from "react-icons/rx";
import { Outlet } from "react-router-dom"; // Import Outlet for nested routes

type Props = {
  logo: string;
};
type NavigationItem = {
  path: string;
  label: string;
  icon: IconType;
};

const Sidebar = ({ logo }: Props) => {
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  const navigationItems: NavigationItem[] = [
    { path: "/ask", label: "Ask", icon: IoChatbubbleOutline },
    { path: "/history", label: "History", icon: RxCounterClockwiseClock },
    { path: "/profile", label: "Profile", icon: GoProjectSymlink },
  ];

  // Effect to update isMobile state based on window width
  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth < 700); // Set a threshold for mobile view
    };
    checkScreenWidth();
    window.addEventListener("resize", checkScreenWidth);
    return () => window.removeEventListener("resize", checkScreenWidth);
  }, []);

  return (
    <div className="w-full h-full bg-black gap-[10px] flex flex-col">
      <div className="app flex flex-col h-screen overflow-auto">
        <div className="flex w-full h-screen">
          {/* Sidebar for non-mobile views */}
          {!isMobile && (
            <div
              className={`rounded-md p-3 flex flex-col gap-4 border-r border-[#41403F] md:w-[18%] xl:w-[19%]`}
            >
              <div>
                <NavLink to="/" className="flex gap-2 items-center">
                  {/* <img src={logo} alt="Logo" className="h-10 w-auto" /> */}
                  <div className="text-[#FF5959] font-medium flex  gap-1 items-center px-10">
                    <span className="text-[14px]">Prashna</span>
                    <span className="text-[17px]">Kundali</span>
                  </div>
                </NavLink>
              </div>
              <ul className="flex flex-col space-y-3 px-2">
                {navigationItems.map((item, index) => (
                  <li key={index}>
                    <Link
                      to={item.path}
                      className={`${
                        location.pathname === item.path
                          ? "bg-[#FF5959] text-white"
                          : "text-[#fff]"
                      } flex  py-[4px] rounded-[40px] hover:bg-[#212121]`}
                    >
                      <span className="flex items-center px-5 gap-4 py-1">
                        <item.icon className="text-[20px]" />
                        <span className="text-[16px] font-medium">
                          {" "}
                          {item.label}
                        </span>
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Main content area */}
          <div className="container mx-auto flex-1 w-full h-full">
            <div className="h-[calc(100%-60px)] overflow-auto">
              <Outlet />
            </div>
          </div>
        </div>

        {/* Bottom navigation for mobile views */}
        {isMobile && (
          <div className="fixed bottom-0 left-0 right-0 bg-black text-white flex justify-around py-3 border-t border-[#41403F] z-10 h-[60px]">
            {navigationItems?.map((item, index) => (
              <NavLink
                key={index}
                to={item.path}
                className={`flex flex-col items-center text-lg font-bold justify-center ${
                  location.pathname === item.path
                    ? "bg-yellow-600 p-2 rounded-full text-black"
                    : "text-white"
                }`}
              >
                <item.icon size={20} />
                {/* <span>{item.label}</span> */}
              </NavLink>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
