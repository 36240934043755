import React from "react";
import youngerAstrologer from "./../../../assets/homeimage2.webp";
import AreiesRashi from "./../../../assets/aries.jpg";
import { BsLightningCharge } from "react-icons/bs";
import { TiWeatherSunny } from "react-icons/ti";
import { FiEdit } from "react-icons/fi";
import { FaDollarSign } from "react-icons/fa";
import { CiLogout } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex flex-col gap-12 lg:w-[50%] m-auto p-8 text-white">
        <div className="flex flex-row gap-3 justify-between">
          <div>
            <div className=" text-white font-semibold text-xl">
              Ashvini Choudhary
            </div>
            <div className=" text-[#818080] text-sm font-semibold">
              ashvinichoudhary1519@gmail.com
            </div>
            <div className=" text-[#818080] text-sm font-semibold">
              Born on 13/06/2004, 17:00:00
            </div>
          </div>
          <div className=" text-white font">
            <img
              src={youngerAstrologer}
              alt=""
              className="w-[80px] h-[80px] rounded-[40px] "
            />
          </div>
        </div>
        <div className="flex flex-row justify-center gap-3  items-center text-sm font-semibold bg-[#41403F] py-[6px] px-3 rounded-2xl text-[#818080] hover:text-white hover:bg-[#FF5959]">
          <BsLightningCharge />0 questions Credits
        </div>
        <div className="flex flex-col gap-5 justify-center items-center">
          <div className=" text-white font">
            <img
              src={AreiesRashi}
              alt=""
              className="w-[150px] h-[150px] rounded-[75px] "
            />
          </div>
          <div className="flex flex-col gap-1">
            <div className="text-white font-bold text-3xl">Aries (Mesh)</div>
            <div className="text-white text-sm font-semibold">
              Sun Jun 13 2004, 17:00:00
            </div>
          </div>
          <div className="flex flex-row gap-2 border border-[#41403F] px-3 py-1 text-sm rounded-xl  items-center w-fit">
            <span className="text-[#DEDE00]">
              <TiWeatherSunny />
            </span>{" "}
            <span className="text-white">Sun Sign </span>{" "}
            <span className="text-[#818080]">Cancer (karkat)</span>
          </div>
          <div
            onClick={() => {
              navigate("/birth_detail");
            }}
            className="flex flex-row justify-center gap-3  items-center text-sm font-semibold border border-[#41403F] w-full py-[6px] px-3 rounded-2xl text-[#E1E1E1]  hover:bg-[#41403F]"
          >
            <FiEdit />
            Edit Birth Details
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <div className="flex flex-row gap-3 text-[#E1E1E1] text-2xl items-center">
            <FaDollarSign />
            Payment History
          </div>
          <div className="text-[#818080] text-md font-semibold  text-center">
            No Payment Found
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <div className="flex flex-row gap-3 text-[#E1E1E1] text-2xl items-center">
            <FaDollarSign />
            Subscription History
          </div>
          <div className="text-[#818080] text-md font-semibold  text-center">
            No Subscription Found
          </div>
        </div>
        <div className="flex flex-row justify-center gap-3 w-fit items-center text-sm font-semibold bg-[#41403F] py-[6px] px-5 rounded-xl text-[#fff]  hover:bg-[#EF4444]">
          <CiLogout />
          Log Out
        </div>
      </div>
    </div>
  );
};

export default Profile;
