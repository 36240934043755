import React from "react";
import image from "./../../../../assets/homeimage.webp";
const MainCard = () => {
  return (
    <div className=" px-4 ">
      <div className="grid lg:grid-cols-2 py-12 gap-10">
        <div className="flex flex-col justify-center items-start">
          <i className="font-thin">
            What you personalized kundli/ birth chart reading ?
          </i>
          <h1 className="lg:text-[45px] text-[30px] font-bold flex flex-col font-sans">
            <span> Get your own </span>
            <span className="text-[#FF5959]">AI Astrologer</span>
          </h1>
          <p className="text-[18px] py-6 lg:w-[550px] font-thin">
            Get clarity on Love, Education, Career, Health, and more with our
            Vedic AstroGPT. Discover the factors influencing your life through
            personalized AI Astrology and Kundli AI insights.
          </p>
          <p className="text-[18px] font-thin">
            Start your journey with{" "}
            <span className="text-[#FF5959]">FREE Birth Chart Analysis</span>{" "}
            (Kundli Generation)
          </p>
          <div className="flex flex-col gap-6 mt-5">
            <button className="bg-[#FF5959] text-white py-2 px-6 rounded-full hover:bg-orange-600">
              Ask your AI Astrologer Now
            </button>
            <button className="bg-gray-800 text-white py-2 px-6 rounded-full hover:bg-gray-700">
              Download on Google Play
            </button>
          </div>
        </div>
        <div className="">
          <img src={image} alt="Astrologer 1" className="auto" />
        </div>
      </div>
    </div>
  );
};

export default MainCard;
