import { Dialog } from "@mui/material";
import React from "react";
import { BsLightningCharge } from "react-icons/bs";
import { FaHotjar } from "react-icons/fa";
import { IoLockClosedOutline } from "react-icons/io5";
import { RiFlashlightLine } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import stripeImage from "./../../assets/powered_by_stripe.png";
type Props = {
  onClose: () => void;
};
const PaymentCard = ({ onClose }: Props) => {
  return (
    <div>
      <Dialog
        open={true}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <div className="bg-[#41403F] flex flex-col gap-5 p-8">
          <div className="flex flex-row gap-3 items-center w-full justify-between ">
            <div className="text-white text-2xl font-semibold  w-full text-center">
              Select Package
            </div>
            <div
              className="text-white text-2xl font-semibold "
              onClick={onClose}
            >
              <RxCross2 />
            </div>
          </div>
          <div className="flex justify-center">
            <div className="text-white bg-[#525251] text-md flex gap-2 w-fit py-2 rounded-3xl px-3 justify-center ">
              <span className="bg-[#FF5959] rounded-2xl px-2 py-[2px] text-black">
                INR
              </span>{" "}
              Indian Rupee
            </div>
          </div>
          <div>
            <div className="flex flex-row text-xl justify-center gap-3  items-center  font-semibold bg-[#41403F] py-[6px] px-3 rounded-2xl text-[#818080] hover:text-white hover:bg-[#FF5959]">
              <BsLightningCharge />0 questions Credits
            </div>
          </div>
          <div className="grid lg:grid-cols-2 gap-5">
            <div className="bg-[#212121] p-8 rounded-xl flex flex-col gap-3 cursor-pointer hover:bg-gradient-to-r from-[#FF5959]">
              <div className="flex flex-row gap-3 justify-between items-center">
                <div className="text-5xl font-bold  bg-gradient-to-r from-[#f0e9e3] to-[#FF5959] bg-clip-text text-transparent">
                  {" "}
                  RS. 49
                </div>
                <div className="flex flex-col  items-end">
                  <div className="flex flex-row font-bold items-center text-3xl text-white gap-2">
                    <RiFlashlightLine /> 1
                  </div>
                  <div className="text-[20px] text-white">Question Credit</div>
                </div>
              </div>
            </div>
            <div className="bg-[#212121] p-8 rounded-xl flex flex-col gap-8 cursor-pointer hover:bg-gradient-to-r from-[#FF5959]">
              <div className="flex flex-row gap-3 justify-between items-center">
                <div className="text-5xl font-bold  bg-gradient-to-r from-[#f0e9e3] to-[#FF5959] bg-clip-text text-transparent">
                  {" "}
                  RS. 200
                </div>
                <div className="flex flex-col  items-end">
                  <div className="flex flex-row font-bold items-center text-3xl text-white gap-2">
                    <RiFlashlightLine /> 7
                  </div>
                  <div className="text-[20px] text-white">Question Credit</div>
                </div>
              </div>
              <div className="flex flex-row justify-between items-center gap-3 cursor-pointer hover:bg-gradient-to-r from-[#FF5959]">
                <div className="text-[#525251] font-medium">
                  Rs 28.7 per question
                </div>
                <div className="flex flex-row gap-3 items-center text-md bg-[#93C5FD] w-fit px-3 py-[3px] rounded-xl font-semibold">
                  <FaHotjar /> Save Rs 143.00
                </div>
              </div>
            </div>
            <div className="bg-[#212121] p-8 rounded-xl flex flex-col gap-8 cursor-pointer hover:bg-gradient-to-r from-[#FF5959]">
              <div className="flex flex-row gap-3 justify-between items-center">
                <div className="text-5xl font-bold  bg-gradient-to-r from-[#f0e9e3] to-[#FF5959] bg-clip-text text-transparent">
                  {" "}
                  RS. 399
                </div>
                <div className="flex flex-col  items-end">
                  <div className="flex flex-row font-bold items-center text-3xl text-white gap-2">
                    <RiFlashlightLine /> 15
                  </div>
                  <div className="text-[20px] text-white">Question Credit</div>
                </div>
              </div>
              <div className="flex flex-row justify-between items-center gap-3">
                <div className="text-[#525251] font-medium">
                  Rs 26.60 per question
                </div>
                <div className="flex flex-row gap-3 items-center text-md bg-[#93C5FD] w-fit px-3 py-[3px] rounded-xl font-semibold">
                  <FaHotjar /> Save Rs 336.00
                </div>
              </div>
            </div>
            <div className="bg-[#212121] p-8 rounded-xl flex flex-col gap-8 cursor-pointer hover:bg-gradient-to-r from-[#FF5959]">
              <div className="flex flex-row gap-3 justify-between items-center">
                <div className="text-5xl font-bold  bg-gradient-to-r from-[#f0e9e3] to-[#FF5959] bg-clip-text text-transparent">
                  {" "}
                  RS. 999
                </div>
                <div className="flex flex-col  items-end">
                  <div className="flex flex-row font-bold items-center text-3xl text-white gap-2">
                    <RiFlashlightLine /> 40
                  </div>
                  <div className="text-[20px] text-white">Question Credit</div>
                </div>
              </div>
              <div className="flex flex-row justify-between items-center gap-3">
                <div className="text-[#525251] font-medium">
                  Rs 26.60 per question
                </div>
                <div className="flex flex-row gap-3 items-center text-md bg-[#93C5FD] w-fit px-3 py-[3px] rounded-xl font-semibold">
                  <FaHotjar /> Save Rs 336.00
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-3 justify-center">
            <div className="text-white text-lg font-semibold flex flex-row items-center gap-3">
              <IoLockClosedOutline /> Secure Payment
            </div>
            <img src={stripeImage} alt="" className="h-10" />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default PaymentCard;
