import React from "react";
import mobileImage from "./../../../../assets/homeimage1.webp";
type Props = {};

const HomeCard2 = (props: Props) => {
  return (
    <div>
      {" "}
      <div className="flex flex-col items-center justify-center text-center ">
        <div className="lg:w-[800px] p-2">
          <h1 className="text-left font-bold orbitron text-3xl dark:text-gray-400 ">
            THE MOST ADVANCED{" "}
            <span className="text-[#FF5959]">AI ASTROLOGY</span> SERVICE
          </h1>
          <p className="font-thin lg:text-[16px] text-[13px] py-2">
            Explore the power of AstroGPT, combining ancient Vedic Astrology
            with advanced AI Astrology Free services. Get instant answers to
            your inquiries with our AI Astrologer. Perfect for those searching
            for “Kundli GPT”, “Star AstroGPT” and “astrologers near me”.
          </p>
        </div>
        <div className="flex flex-col items-center justify-center py-12">
          <img src={mobileImage} alt="#" className="w-[90%]" />
        </div>
      </div>
    </div>
  );
};

export default HomeCard2;
