import React, { useEffect, useState } from "react";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TiArrowSortedDown } from "react-icons/ti";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
const BirthDetail = () => {
  const [allCitiesData, setAllCitiesData] = useState<any>([]);
  const mapData: any = [];
  const [filteredDataSource, setFilteredDataSource] = useState<any>(mapData);
  const [searchValue, setSearchValue] = useState("");

  const searchFilterFunction = (text: string) => {
    // Check if searched text is not blank
    if (text) {
      // const newData = mapData.filter((ele) => {
      //     if (ele?.address.toUpperCase().includes(text?.toUpperCase())) {
      //         return ele;
      //     }
      // })
      const regex = new RegExp(`\\b${text.trim()}\\b, 'i'`);
      const newData = mapData?.filter((ele: any) => {
        return regex.test(ele?.address);
      });
      setFilteredDataSource(newData);
      setSearchValue(text);
    } else {
      // Inserted text is blank
      // Update FilteredDataSource with masterDataSource
      setFilteredDataSource(mapData);
      setSearchValue(text);
    }
  };
  const getCityData = () => {
    axios
      .get("https://countriesnow.space/api/v0.1/countries/population/cities")
      .then((res: any) => {
        setAllCitiesData(res?.data?.data);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCityData();
  }, []);
  const customMenuProps = {
    PaperProps: {
      sx: {
        bgcolor: "black",
        color: "white",
        ".MuiMenuItem-root": {
          color: "white",
          fontStyle: "normal",
          "&:hover": {
            bgcolor: "blue",
          },
        },
      },
    },
  };
  const handleSubmit = (values: any) => {
    console.log("Form submitted:", values);
  };
  return (
    <div>
      <div className="xl:w-[50%] m-auto p-10 flex flex-col gap-10">
        <div>
          <div className=" text-white font-semibold text-xl">
            Birth Information
          </div>
          <div className=" text-white text-sm font-semibold">
            Accurate birth information is crucial for precise astrological
            analysis. Provide the correct date, time, and location to unlock
            profound insights.
          </div>
        </div>
        <Formik
          initialValues={{
            location: "",
            birthDateTime: null,
            gender: "",
          }}
          // validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, setFieldValue, handleChange }) => (
            <Form className="flex flex-col gap-10">
              <div className="flex flex-col gap-10">
                <div className="flex flex-col gap-3">
                  <div className=" text-white text-lg font-medium">
                    Select Your Birth Location
                  </div>
                  <Autocomplete
                    options={allCitiesData}
                    getOptionLabel={(option: any) => option.city}
                    // onChange={(event, newValue) => {
                    //     if (newValue) {
                    //         setSearchValue(newValue.city);
                    //         searchFilterFunction(newValue.city);
                    //     }
                    // }}
                    onChange={(event, newValue) => {
                      setFieldValue("location", newValue?.city || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Enter Your Place"
                        variant="outlined"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            color: "white", // Text color
                            backgroundColor: "#333", // Background color
                            borderRadius: "25px",
                            height: 40,
                            ".MuiOutlinedInput-notchedOutline": {
                              // borderColor: 0, // Border color
                              borderWidth: 0,
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              border: 0,
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              border: 0,
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: "lightblue",
                          },
                          "& .MuiInputLabel-root.Mui-focused": {
                            color: "white",
                          },
                        }}
                        value={searchValue}
                        onChange={(event) => {
                          searchFilterFunction(event.target.value);
                          setSearchValue(event.target.value);
                        }}
                      />
                    )}
                    ListboxProps={{
                      style: {
                        backgroundColor: "black",
                        color: "white",
                        borderRadius: "8px",
                      },
                    }}
                    sx={{
                      ".MuiAutocomplete-popupIndicator": {
                        color: "white",
                      },
                      ".MuiAutocomplete-clearIndicator": {
                        color: "white",
                      },
                    }}
                  />
                </div>
                <div className="flex flex-col gap-3">
                  <div className=" text-white text-lg font-medium">
                    Date and Time of Birth
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      value={values.birthDateTime}
                      onChange={(newValue) =>
                        setFieldValue("birthDateTime", newValue)
                      }
                      //   onChange={()=>{}}
                      name="birthDateTime "
                      sx={{
                        ".MuiOutlinedInput-root": {
                          color: "white",
                          backgroundColor: "#333",
                          borderRadius: "25px",
                          height: 40,
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          border: 0,
                        },
                        ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            border: 0,
                          },
                        ".MuiInputAdornment-root .MuiButtonBase-root": {
                          color: "white",
                        },
                        ".MuiPickersDay-dayWithMargin": {
                          color: "white",
                        },
                        ".Mui-selected": {
                          backgroundColor: "#6200ea",
                        },
                      }}
                      views={["year", "month", "day", "hours", "minutes"]}
                    />
                  </LocalizationProvider>
                </div>
                <div className="flex flex-col gap-3">
                  <div className=" text-white text-lg font-medium">
                    Select Gender
                  </div>
                  <Select
                    value={values.gender}
                    onChange={handleChange}
                    name="gender"
                    defaultValue=""
                    displayEmpty
                    MenuProps={customMenuProps}
                    sx={{
                      color: "white",
                      backgroundColor: "#333",
                      borderRadius: "25px",
                      fontStyle: "normal",
                      height: 40,
                      borderWidth: 0,
                      ".MuiOutlinedInput-notchedOutline": {
                        borderWidth: 0,
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: 0,
                      },
                      ".MuiSvgIcon-root": {
                        color: "white",
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em>Select an option</em>
                    </MenuItem>
                    <MenuItem value={"Female"}>Female</MenuItem>
                    <MenuItem value={"Male"}>Male</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                    <MenuItem value={"Not Prefer"}>Not Prefer To Say</MenuItem>
                  </Select>
                </div>
              </div>
              <button
                type="submit"
                className="bg-[#FF5959] text-white py-2 px-4 rounded-lg hover:[#D9873C]"
              >
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default BirthDetail;
