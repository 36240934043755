import React, { useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { FaChevronDown, FaBars } from "react-icons/fa";
import { BsCheck } from "react-icons/bs";
import { Rating } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";

interface NavLinkItem {
  label: string;
  path: string;
  icons?: any;
  dropdownLinks?: { label: string; path: string }[];
}

interface TopNavbarProps {
  logo: string;
  links: NavLinkItem[];
}

const Navbar: React.FC<TopNavbarProps> = ({ logo, links }) => {
  const naigate = useNavigate();
  const [hoveredLink, setHoveredLink] = useState<string | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const basicData = ["Fully Personalized", "Real Time Reading"];
  const handleDialogOpen = () => setIsDialogOpen(true);
  const handleDialogClose = () => setIsDialogOpen(false);

  return (
    <div className="h-screen flex flex-col">
      {/* Navbar */}
      <div className="">
        {/* Top Bar */}
        <div className="bg-[#212121] p-[1px] flex justify-between items-center gap-4 px-10 py-2">
          <div className="flex gap-2 items-center">
            ``
            {basicData.map((item, index) => (
              <div
                key={index}
                className="flex gap-1 items-center text-gray-400"
              >
                <BsCheck />
                <span className="text-[12px] font-normal">{item}</span>
              </div>
            ))}
          </div>
          <div className="flex gap-2 text-[12px] text-gray-400">
            <Rating name="disabled" value={5} disabled size="small" />
            <span className="capitalize">4.3 star rating</span>
          </div>
        </div>

        {/* Main Navbar */}
        <div className="bg-[#131313] text-white">
          <div className="mx-auto flex justify-between items-center px-10 py-3">
            {/* Logo */}
            <div className="flex gap-4">
              <NavLink to="/" className="flex gap-2 items-center">
                {/* <img src={logo} alt="Logo" className="h-10 w-auto" />  */}
                <div className="flex flex-col text-[#FF5959] font-medium">
                  <span className="text-[14px]">Prashna</span>
                  <span className="text-[17px]">Kundali</span>
                </div>
              </NavLink>

              {/* Desktop Navigation */}
              <ul className="hidden md:flex gap-5">
                {links.map((link) => (
                  <li
                    key={link.path}
                    onMouseEnter={() => setHoveredLink(link.path)}
                    onMouseLeave={() => setHoveredLink(null)}
                  >
                    <NavLink
                      to={link.path}
                      className={({ isActive }) =>
                        `flex items-center gap-1 px-2 py-3 rounded font-normal hover:text-[#FF5959] ${
                          isActive ? "text-[#FF5959]" : ""
                        }`
                      }
                    >
                      {link?.icons && <div>{link?.icons}</div>}
                      {link.label}
                      {link.dropdownLinks && (
                        <FaChevronDown className="text-sm mt-[2px]" />
                      )}
                    </NavLink>
                    {/* Full-Screen Dropdown Menu */}
                    {hoveredLink === link.path && link.dropdownLinks && (
                      <div className="absolute top-[65px] left-0 w-screen bg-[#000000] shadow-lg z-10 p-6 border-b-[1px] border-gray-600">
                        <div className="container mx-auto gap-4 flex items-center justify-center">
                          {link.dropdownLinks.map((dropdownLink) => (
                            <div key={dropdownLink.path}>
                              <NavLink
                                to={dropdownLink.path}
                                className="block text-white hover:text-[#FF5959]"
                              >
                                {dropdownLink.label}
                              </NavLink>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <button
                className="bg-[#FF5959] text-white px-6 py-2 rounded-full font-medium"
                onClick={() => naigate("/login")}
              >
                Login
              </button>
            </div>
            {/* Mobile Menu Icon */}
            <div className="md:hidden">
              <IconButton onClick={handleDialogOpen}>
                <FaBars className="text-white text-xl" />
              </IconButton>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Dialog */}
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        fullScreen
        className="p-5"
      >
        <div className="bg-[#313131] h-full text-white flex flex-col px-6 py-6">
          <button
            className="text-white text-xl flex flex-col items-end px-10 font-bold"
            onClick={handleDialogClose}
          >
            ✕
          </button>
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-3xl font-bold flex flex-col">
              More on Vedic <span>AstroGPT</span>
            </h2>
          </div>
          <ul className="flex flex-col gap-6">
            {links.map((link) => (
              <li key={link.path}>
                <NavLink
                  to={link.path}
                  onClick={handleDialogClose}
                  className="block text-white hover:text-[#FF5959] text-[18px] font-medium"
                >
                  {link.label}
                </NavLink>
                {link?.dropdownLinks && (
                  <ul className="ml-4 mt-2 text-sm flex flex-col gap-2">
                    {link.dropdownLinks.map((dropdownLink) => (
                      <li key={dropdownLink.path}>
                        <NavLink
                          to={dropdownLink.path}
                          onClick={handleDialogClose}
                          className="block text-gray-300 hover:text-[#FF5959] text-[16px]"
                        >
                          {dropdownLink.label}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      </Dialog>

      {/* Content Section */}
      <div className="flex-1 overflow-y-auto bg-[#000000] text-white p-4 h-screen">
        <Outlet />
      </div>
    </div>
  );
};

export default Navbar;
