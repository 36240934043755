import React from "react";
import userImage from "./../../../assets/userimage.jpg";
import careerImage from "./../../../assets/career1.jpg";
type Props = {};

const AboutUs = (props: Props) => {
  const offerData = [
    {
      title: "Career & Professional Life",
      description:
        "Discover opportunities and overcome challenges based on planetary alignments.",
      image: careerImage,
    },
    {
      title: "Marriage & Family",
      description:
        "Navigate life’s obstacles with predictions and suggestions to enhance harmony.",
      image: careerImage,
    },
    {
      title: "Doshas & Remedies",
      description:
        "Identify negative influences in your kundli and receive effective solutions.",
      image: careerImage,
    },
    {
      title: "Health",
      description:
        "Gain insights into potential health issues and preventative measures.",
      image: careerImage,
    },
    {
      title: "Finances",
      description:
        "Improve your financial outlook with personalized astrological guidance.",
      image: careerImage,
    },
  ];

  const memberData = [
    {
      name: "Codelab7",
      role: "Web Development Solutions",
      image: userImage,
    },
    {
      name: "Raj Sutariya",
      role: "Machine Learning",
      image: userImage,
    },
    {
      name: "Ankit Vaghasiya",
      role: "Data Science and AI",
      image: userImage,
    },
  ];

  return (
    <div className="min-h-screen text-white">
      {/* Header Section */}
      <header className="py-12 text-center">
        <h1 className="text-5xl font-bold text-[#FF5959]">About Us</h1>
        <p className="mt-4 text-lg">
          We are a small, passionate team simplifying Vedic Astrology with AI.
        </p>
      </header>

      {/* Vision and Mission */}
      <section className="px-8 lg:px-32 py-8 text-center">
        <h2 className="text-4xl font-semibold text-gray-200 mb-6">
          Our Vision and Mission
        </h2>
        <p className="text-lg mb-4">
          At Kundli GPT, our vision is to empower individuals with personalized
          astrological insights to help them navigate their lives with clarity
          and confidence. Our mission is to merge ancient Vedic wisdom with
          modern AI technology, delivering accurate and actionable advice for
          everyone.
        </p>
        <blockquote className="italic text-[#FF5959] mt-6">
          “The stars may guide us, but it’s the actions we take that shape our
          destiny.”
        </blockquote>
      </section>

      {/* What We Offer */}
      <section className="px-8 lg:px-32 py-8">
        <h2 className="text-4xl font-semibold text-center mb-8 ">
          What We Offer
        </h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {offerData.map((item, index) => (
            <div
              key={index}
              className="bg-[#131313] rounded-lg shadow-lg overflow-hidden hover:scale-105 transform transition-all duration-300"
            >
              <img
                src={item.image}
                alt={item.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <h3 className="text-xl font-semibold mb-4">{item.title}</h3>
                <p className="text-sm">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Team Section */}
      <section className="px-8 lg:px-32 py-8">
        <h2 className="text-4xl font-semibold text-center mb-8 text-[#FF5959]">
          Meet Our Team
        </h2>
        <div className="grid md:grid-cols-3 gap-8">
          {memberData.map((member, index) => (
            <div
              key={index}
              className="bg-[#131313] rounded-lg shadow-lg text-center hover:scale-105 transform transition-all duration-300"
            >
              <img
                src={member.image}
                alt={member.name}
                className="w-24 h-24 mx-auto mt-6 rounded-full object-cover"
              />
              <div className="p-6">
                <h3 className="text-xl font-semibold mb-2">{member.name}</h3>
                <p className="text-sm">{member.role}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Testimonials */}
      <section className="px-8 lg:px-32 py-8 bg-[#131313]">
        <h2 className="text-4xl font-semibold text-center mb-8 text-[#FF5959]">
          Testimonials
        </h2>
        <div className="grid md:grid-cols-2 gap-8">
          <div className="bg-[#000000] p-6 rounded-lg shadow-lg">
            <p className="italic">
              “Kundli GPT has given me invaluable insights into my career path.
              The remedies were spot-on and easy to follow!”
            </p>
            <p className="mt-4 font-semibold text-[#FF5959]">- Ravi Sharma</p>
          </div>
          <div className="bg-[#000000] p-6 rounded-lg shadow-lg">
            <p className="italic">
              “I loved the personalized predictions for my marriage. It’s like
              having my personal astrologer 24/7!”
            </p>
            <p className="mt-4 font-semibold text-[#FF5959]">- Ananya Singh</p>
          </div>
        </div>
      </section>

      {/* Disclaimer */}
      <section className="px-8 lg:px-32 py-8 bg-[#000000]">
        <h2 className="text-2xl font-semibold text-center mb-4 text-[#FF5959]">
          DISCLAIMER
        </h2>
        <p className="text-sm text-center">
          Kundli GPT is an experimental tool designed for general informational
          purposes only. While we strive for accuracy, we cannot guarantee the
          reliability or suitability of the information provided. Use the
          chatbot at your own discretion. Kundli GPT is an experimental tool
          designed for general informational purposes only. While we strive for
          accuracy, we cannot guarantee the reliability or suitability of the
          information provided. Use the chatbot at your own discretion. Kundli
          GPT is an experimental tool designed for general informational
          purposes only. While we strive for accuracy, we cannot guarantee the
          reliability or suitability of the information provided. Use the
          chatbot at your own discretion. Kundli GPT is an experimental tool
          designed for general informational purposes only. While we strive for
          accuracy, we cannot guarantee the reliability or suitability of the
          information provided. Use the chatbot at your own discretion. Kundli
          GPT is an experimental tool designed for general informational
          purposes only. While we strive for accuracy, we cannot guarantee the
          reliability or suitability of the information provided. Use the
          chatbot at your own discretion.
        </p>
      </section>
    </div>
  );
};

export default AboutUs;
