import React from "react";
import { PiStarFourBold } from "react-icons/pi";
import { IoChatbubbleOutline } from "react-icons/io5";
import { BsStars } from "react-icons/bs";
import PriceCard from "../../../components/common/PricingCard";

type Props = {};

const Pricing = (props: Props) => {
  return (
    <div className="h-screen">
      <header className="py-12 text-center">
        <h1 className="text-5xl font-bold text-[#FF5959]">Pricing</h1>
        <p className="mt-4 text-lg">
          We are a small, passionate team simplifying Vedic Astrology with AI.
        </p>
      </header>
      <div className=" flex flex-col items-center justify-center">
        <div className="container">
          <div className="p-4 rounded-xl border border-green-300 flex flex-col gap-4">
            <div className="flex flex-col md:flex-row items-center justify-between text-white gap-4">
              <div className="flex flex-col gap-3 flex-1 w-full">
                <div className="text-xl font-bold flex items-center gap-3">
                  <BsStars />
                  Birth Chart Analysis (AI)
                </div>
                <div className="text-sm font-bold text-white px-4 py-1 bg-green-800 w-fit rounded-xl">
                  FREE
                </div>
              </div>
              <div className="flex flex-col gap-2 flex-1 w-full">
                <div className="text-center md:text-left flex items-center gap-3">
                  <PiStarFourBold className="text-[18px]" />
                  Kundli/Chart Generation
                </div>
                <div className="text-center md:text-left flex items-center gap-3">
                  <PiStarFourBold className="text-[18px]" />
                  Kundli/Chart Interpretation
                </div>
              </div>
              <div className="flex items-center justify-center">
                <a
                  href="https://app.vedicastrogpt.com"
                  title="Navigate to Vedic AstroGPT app"
                  aria-label="Login to continue to Vedic AstroGPT app"
                  aria-labelledby="text"
                  rel="nofollow"
                  className="bg-[#FF5959] text-whitw font-medium py-2 px-6 rounded-full hover:bg-orange-[#FF5959] flex items-center gap-1"
                >
                  <IoChatbubbleOutline />
                  Get Started for Free
                </a>
              </div>
            </div>
          </div>
          <div className="py-10 ">
            <PriceCard />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
