import React from "react";
import FaqCard from "../../../../components/common/FaqCard";

type Props = {};

const HomeFaq = (props: Props) => {
  const faqData = [
    {
      title: "What is Vedic Astrology?",
      description:
        "Vedic astrology, a tradition over 7,000 years old, originates from Nepal and India. It is among the most detailed astrological systems still in use, grounded in precise sidereal astronomy. Recognized for its secular and mathematical approach, Vedic astrology steers clear of superstitions and relies on a profound understanding of astronomical principles.",
    },
    {
      title: "How does Vedic Astrology differ from Western Astrology?",
      description:
        "Vedic astrology uses the sidereal zodiac, which is based on the actual constellations in the sky, whereas Western astrology uses the tropical zodiac. This leads to differences in the positions of the planets in each system.",
    },
    {
      title: "What are the benefits of Vedic Astrology?",
      description:
        "Vedic astrology provides insights into an individual's personality, relationships, career, health, and future opportunities. It offers remedies to mitigate negative influences and maximize positive outcomes.",
    },
    {
      title: "What is a Birth Chart in Vedic Astrology?",
      description:
        "A birth chart, or Kundli, is a map of the planets at the exact time of an individual's birth. It is used to understand their strengths, weaknesses, and life path.",
    },
    {
      title: "Can Vedic Astrology predict the future?",
      description:
        "Vedic astrology can provide a detailed analysis of planetary influences and trends over time, which may indicate potential outcomes and future possibilities.",
    },
    {
      title: "What are Dasha periods in Vedic Astrology?",
      description:
        "Dasha periods are planetary periods that influence specific phases of an individual's life. These periods can provide insights into challenges and opportunities during that time.",
    },
    {
      title: "Is Vedic Astrology based on science?",
      description:
        "Vedic astrology is grounded in precise astronomical principles and mathematical calculations. However, its interpretation and applications also involve spiritual and intuitive elements.",
    },
  ];

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="container">
        <div className="text-3xl md:text-5xl text-center md:text-left text-white font-bold text-gradient py-1 font-mono">
          FAQs
        </div>
        <div className="max-w-lg text-gray-400 text-center md:text-left">
          Get answers to most asked questions about Vedic Astrology, AI
          Astrology and AI Astrologers
        </div>
        <div className="flex flex-col gap-6 pt-10">
          {faqData?.map((faq, index) => (
            <FaqCard
              key={index}
              title={faq.title}
              description={faq.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeFaq;
