import {
  FaCalculator,
  FaDollarSign,
  FaHeart,
  FaRegCalendar,
} from "react-icons/fa6";
import { IoHome } from "react-icons/io5";
import { PiToolboxThin } from "react-icons/pi";

export const categoryData = [
  {
    category: "Personality",
    catIcon: FaRegCalendar,
    subCategory: [
      {
        subCategoryTitle: "Strengths & Weaknesses",
        questionData: [
          {
            id: "1",
            question: "What are your top strengths?",
            questionImage: "https://example.com/images/strengths.png",
          },
          {
            id: "2",
            question: "What are the areas you want to improve?",
            questionImage: "https://example.com/images/weaknesses.png",
          },
        ],
      },
      {
        subCategoryTitle: "Perosonal Growth",
        questionData: [
          {
            id: "1",
            question: "What are your top strengths?",
            questionImage: "https://example.com/images/strengths.png",
          },
          {
            id: "2",
            question: "What are the areas you want to improve?",
            questionImage: "https://example.com/images/weaknesses.png",
          },
        ],
      },
      {
        subCategoryTitle: "Leadership Potential",
        questionData: [
          {
            id: "1",
            question: "What are your top strengths?",
            questionImage: "https://example.com/images/strengths.png",
          },
          {
            id: "2",
            question: "What are the areas you want to improve?",
            questionImage: "https://example.com/images/weaknesses.png",
          },
        ],
      },
      {
        subCategoryTitle: "Intutuition & clairvoyance",
        questionData: [
          {
            id: "1",
            question: "What are your top strengths?",
            questionImage: "https://example.com/images/strengths.png",
          },
          {
            id: "2",
            question: "What are the areas you want to improve?",
            questionImage: "https://example.com/images/weaknesses.png",
          },
        ],
      },
      {
        subCategoryTitle: "Happiness & Fulfillment",
        questionData: [
          {
            id: "1",
            question: "What are your top strengths?",
            questionImage: "https://example.com/images/strengths.png",
          },
          {
            id: "2",
            question: "What are the areas you want to improve?",
            questionImage: "https://example.com/images/weaknesses.png",
          },
        ],
      },
    ],
  },
  {
    category: "Love",
    catIcon: FaHeart,
    subCategory: [
      {
        subCategoryTitle: "Love Awaits Me",
        questionData: [
          {
            id: "1",
            question: "What are your immediate career goals?",
            questionImage: "https://example.com/images/career_goals.png",
          },
          {
            id: "2",
            question: "What long-term career achievements do you aim for?",
            questionImage: "https://example.com/images/aspirations.png",
          },
        ],
      },
      {
        subCategoryTitle: "Future Marriage",
        questionData: [
          {
            id: "1",
            question: "What are your immediate career goals?",
            questionImage: "https://example.com/images/career_goals.png",
          },
          {
            id: "2",
            question: "What long-term career achievements do you aim for?",
            questionImage: "https://example.com/images/aspirations.png",
          },
        ],
      },
      {
        subCategoryTitle: "Current Partner",
        questionData: [
          {
            id: "1",
            question: "What are your immediate career goals?",
            questionImage: "https://example.com/images/career_goals.png",
          },
          {
            id: "2",
            question: "What long-term career achievements do you aim for?",
            questionImage: "https://example.com/images/aspirations.png",
          },
        ],
      },
      {
        subCategoryTitle: "Ex Relationship",
        questionData: [
          {
            id: "1",
            question: "What are your immediate career goals?",
            questionImage: "https://example.com/images/career_goals.png",
          },
          {
            id: "2",
            question: "What long-term career achievements do you aim for?",
            questionImage: "https://example.com/images/aspirations.png",
          },
        ],
      },
    ],
  },

  {
    category: "Career ",
    catIcon: PiToolboxThin,
    subCategory: [
      {
        subCategoryTitle: "Physical Wellbeing",
        questionData: [
          {
            id: "1",
            question: "How do you maintain physical fitness?",
            questionImage: "https://example.com/images/fitness.png",
          },
          {
            id: "2",
            question: "What challenges do you face in staying active?",
            questionImage: "https://example.com/images/challenges.png",
          },
        ],
      },
      {
        subCategoryTitle: "Work-life-Balance",
        questionData: [
          {
            id: "1",
            question: "How do you stay mentally focused?",
            questionImage: "https://example.com/images/focus.png",
          },
          {
            id: "2",
            question: "What strategies do you use to handle stress?",
            questionImage: "https://example.com/images/stress.png",
          },
        ],
      },
      {
        subCategoryTitle: "Academic Influence",
        questionData: [
          {
            id: "1",
            question: "How do you stay mentally focused?",
            questionImage: "https://example.com/images/focus.png",
          },
          {
            id: "2",
            question: "What strategies do you use to handle stress?",
            questionImage: "https://example.com/images/stress.png",
          },
        ],
      },
    ],
  },
  {
    category: "Buisness",
    catIcon: FaDollarSign,
    subCategory: [
      {
        subCategoryTitle: "Enterpreneuirial Skills",
        questionData: [
          {
            id: "1",
            question: "What role does family play in your life?",
            questionImage: "https://example.com/images/family.png",
          },
          {
            id: "2",
            question: "How do you maintain close friendships?",
            questionImage: "https://example.com/images/friends.png",
          },
        ],
      },
      {
        subCategoryTitle: "Buisness Direaction",
        questionData: [
          {
            id: "1",
            question: "What role does family play in your life?",
            questionImage: "https://example.com/images/family.png",
          },
          {
            id: "2",
            question: "How do you maintain close friendships?",
            questionImage: "https://example.com/images/friends.png",
          },
        ],
      },
      {
        subCategoryTitle: "Future Outlook",
        questionData: [
          {
            id: "1",
            question: "What role does family play in your life?",
            questionImage: "https://example.com/images/family.png",
          },
          {
            id: "2",
            question: "How do you maintain close friendships?",
            questionImage: "https://example.com/images/friends.png",
          },
        ],
      },
    ],
  },
  {
    category: "Karma And Destiny",
    catIcon: FaDollarSign,
    subCategory: [
      {
        subCategoryTitle: "Life Lessons",
        questionData: [
          {
            id: "1",
            question: "What role does family play in your life?",
            questionImage: "https://example.com/images/family.png",
          },
          {
            id: "2",
            question: "How do you maintain close friendships?",
            questionImage: "https://example.com/images/friends.png",
          },
        ],
      },
      {
        subCategoryTitle: "spiritual Growth",
        questionData: [
          {
            id: "1",
            question: "What role does family play in your life?",
            questionImage: "https://example.com/images/family.png",
          },
          {
            id: "2",
            question: "How do you maintain close friendships?",
            questionImage: "https://example.com/images/friends.png",
          },
        ],
      },
      {
        subCategoryTitle: "Dream Interpretation",
        questionData: [
          {
            id: "1",
            question: "What role does family play in your life?",
            questionImage: "https://example.com/images/family.png",
          },
          {
            id: "2",
            question: "How do you maintain close friendships?",
            questionImage: "https://example.com/images/friends.png",
          },
        ],
      },
    ],
  },
  {
    category: "Finance",
    catIcon: FaCalculator,
    subCategory: [
      {
        subCategoryTitle: "Future FInance Outlook",
        questionData: [
          {
            id: "1",
            question: "What role does family play in your life?",
            questionImage: "https://example.com/images/family.png",
          },
          {
            id: "2",
            question: "How do you maintain close friendships?",
            questionImage: "https://example.com/images/friends.png",
          },
        ],
      },
      {
        subCategoryTitle: "Investment and Saving",
        questionData: [
          {
            id: "1",
            question: "What role does family play in your life?",
            questionImage: "https://example.com/images/family.png",
          },
          {
            id: "2",
            question: "How do you maintain close friendships?",
            questionImage: "https://example.com/images/friends.png",
          },
        ],
      },
      {
        subCategoryTitle: "Charetable Acts and Inheritence",
        questionData: [
          {
            id: "1",
            question: "What role does family play in your life?",
            questionImage: "https://example.com/images/family.png",
          },
          {
            id: "2",
            question: "How do you maintain close friendships?",
            questionImage: "https://example.com/images/friends.png",
          },
        ],
      },
    ],
  },
  {
    category: "Home And Family",
    catIcon: IoHome,
    subCategory: [
      {
        subCategoryTitle: "Home Decor",
        questionData: [
          {
            id: "1",
            question: "What role does family play in your life?",
            questionImage: "https://example.com/images/family.png",
          },
          {
            id: "2",
            question: "How do you maintain close friendships?",
            questionImage: "https://example.com/images/friends.png",
          },
        ],
      },
      {
        subCategoryTitle: "Relocation and Remodeling",
        questionData: [
          {
            id: "1",
            question: "What role does family play in your life?",
            questionImage: "https://example.com/images/family.png",
          },
          {
            id: "2",
            question: "How do you maintain close friendships?",
            questionImage: "https://example.com/images/friends.png",
          },
        ],
      },
    ],
  },
];
