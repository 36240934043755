import React, { useState } from "react";
import youngerAstrologer from "./../../../assets/homeimage2.webp";
import { LuMoonStar } from "react-icons/lu";
import { PiStarFourBold } from "react-icons/pi";
import { BsStars } from "react-icons/bs";
import { TiWeatherSunny } from "react-icons/ti";
import AreiesRashi from "./../../../assets/aries.jpg";

const History = () => {
  const [showBirthChart, setShowBirthChart] = useState(false);
  const astrologerAnalysis = [
    {
      id: 1,
      question: "What type of jobs suit me the best?",
      astrologerName: "Pawan Paudel",
      analysis: [
        "The friendly aspect of Moon enhances your creativity and emotional intelligence, making you suitable for roles that require innovative thinking and teamwork.",
        "Saturn's neutral aspect indicates a disciplined approach to your career, suggesting that structured environments like corporate jobs or research roles may suit you well.",
        "Rahu's neutral aspect can bring unconventional opportunities, hinting at careers in technology or data science where innovation is key.",
        "With Jupiter in Leo, you are likely to excel in leadership roles or creative fields, such as data science, software engineering, or even entrepreneurship.",
        "The conjunction of Mercury with Sun suggests strong communication skills, making you well-suited for roles in law, consulting, or any field requiring negotiation and persuasion.",
        "Venus' influence indicates a flair for aesthetics and creativity, suggesting careers in design, arts, or marketing could also be fulfilling for you.",
        "With the Sun in Taurus, you may find success in careers that require persistence and a practical approach, such as law, finance, or healthcare.",
        "The challenging conjunction with Mars suggests that you may face obstacles in high-pressure environments, indicating that a balanced work-life approach is essential for your success.",
        "Jupiter's aspect on Saturn reinforces the idea of a career that combines discipline with creativity, making fields like data analysis or software development particularly appealing.",
        "Ketu's aspect suggests a potential for spiritual or humanitarian work, indicating that careers in healthcare or social services could also resonate with you.",
        "Saturn in Gemini suggests that you may thrive in careers that require communication and analytical skills, such as data science, law, or healthcare.",
      ],
      summary:
        "Based on your astrological chart, you are well-suited for modern jobs in fields such as data science, software engineering, law, and healthcare. Your strengths in communication and creativity, combined with a disciplined approach to work, will serve you well in these areas. Embrace opportunities that allow you to express your unique talents while maintaining a balanced work-life approach to thrive.",
    },
    {
      id: 2,
      question: "What is the best career path for my skills and personality?",
      astrologerName: "Astro Guru",
      analysis: [
        "Mars in Aries enhances your dynamic leadership skills, making you suitable for high-energy roles like entrepreneurship, sports, or military service.",
        "The placement of Venus in Pisces suggests a strong inclination towards creative arts and design, especially in areas requiring emotional depth and aesthetics.",
        "Moon in Cancer indicates a nurturing and empathetic personality, ideal for careers in teaching, healthcare, or counseling.",
        "Mercury's strength in Virgo highlights your analytical skills, making you an excellent fit for roles in research, data analysis, or financial planning.",
      ],
      summary:
        "Your astrological chart suggests you excel in roles requiring leadership, creativity, or empathy. Careers in entrepreneurship, healthcare, design, or data analysis are particularly favorable. Utilize your analytical and nurturing abilities to thrive in both personal and professional life.",
    },
  ];
  return (
    <div className="relative hide-scrollbar h-screen overflow-auto">
      <div className="absolute inset-0 opacity-40 z-0 askPageBackground "></div>
      <div className="relative h-full flex flex-col gap-5 overflow-y-auto p-5 z-100 bg-transparent hide-scrollbar">
        <div className="relative z-10 flex flex-col gap-10 p-5  ">
          <div className="flex flex-row gap-3">
            <div className=" text-white">
              <img
                src={youngerAstrologer}
                alt=""
                className="w-[50px] h-[50px] rounded-3xl border border-[#41403F]"
              />
            </div>
            <div>
              <div className=" text-white font-semibold text-xl">
                Ashvini Choudhary
              </div>
              <div className=" text-white text-sm">You </div>
            </div>
          </div>
          <div className="flex flex-col gap-10">
            {astrologerAnalysis?.map((history) => {
              return (
                <div className="flex flex-col gap-10">
                  <div className="text-[#dfdada] font-regular text-3xl">
                    {history?.question}
                  </div>
                  <div className="flex flex-row gap-3">
                    <div className=" text-white">
                      <img
                        src={youngerAstrologer}
                        alt=""
                        className="w-[50px] h-[50px] rounded-3xl border border-[#41403F]"
                      />
                    </div>
                    <div>
                      <div className=" text-white font-semibold text-xl">
                        {history?.astrologerName}
                      </div>
                      <div className=" text-white text-sm">AI Astrologer</div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-row gap-2 items-center text-xl font-medium  bg-gradient-to-r from-[#FF5959] to-[#fff] bg-clip-text text-transparent ">
                      <span className="text-[#FF5959]">
                        <LuMoonStar />{" "}
                      </span>{" "}
                      Analysis
                    </div>

                    <ul className="list-none space-y-3 mb-6">
                      {history?.analysis.map((point, index) => (
                        <li key={index} className="flex items-center space-x-3">
                          <span className=" text-gray-300 text-sm">
                            <PiStarFourBold />{" "}
                          </span>
                          <p className="text-gray-300">{point}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-row gap-2 items-center text-xl font-medium  bg-gradient-to-r from-[#FF5959] to-[#fff] bg-clip-text text-transparent ">
                      <span className="text-[#FF5959]">
                        <BsStars />{" "}
                      </span>{" "}
                      Summary
                    </div>
                    <div className="font-medium text-[#DEC9B6] text-lg">
                      {history.summary}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="border-t border-[#403F3E]">
          <div className="p-5">
            {!showBirthChart ? (
              <div className="flex items-center justify-center w-full">
                <button
                  onClick={() => {
                    setShowBirthChart(true);
                  }}
                  type="button"
                  className="bg-[#FF5959] text-lg text-white font-semibold py-2 px-4 rounded-3xl hover:[#D9873C]"
                >
                  Load More
                </button>
              </div>
            ) : (
              <div className="flex flex-col gap-12">
                <div className="flex flex-row gap-3 bg-[#090908] py-3">
                  <div className=" text-white">
                    <img
                      src={youngerAstrologer}
                      alt=""
                      className="w-[50px] h-[50px] rounded-3xl border border-[#41403F]"
                    />
                  </div>
                  <div>
                    <div className=" text-white font-semibold text-xl">
                      Ashvini Choudhary
                    </div>
                    <div className=" text-white text-sm">You </div>
                  </div>
                </div>
                <div className="text-[#dfdada] font-regular text-3xl">
                  Explain my birth chart.
                </div>
                <div className="flex flex-row gap-3 bg-[#090908] py-3">
                  <div className=" text-white">
                    <img
                      src={youngerAstrologer}
                      alt=""
                      className="w-[50px] h-[50px] rounded-3xl border border-[#41403F]"
                    />
                  </div>
                  <div>
                    <div className=" text-white font-semibold text-xl">
                      Pawan Paudel
                    </div>
                    <div className=" text-white text-sm">AI Astrologer</div>
                  </div>
                </div>
                <div className="w-[50%] m-auto flex flex-col gap-4 ">
                  <div className="text-[#F7B477] font-bold text-3xl">
                    Your Birth Chart
                  </div>
                  <div className="flex flex-col gap-5 justify-center items-center bg-[#090908]">
                    <div className=" text-white font">
                      <img
                        src={AreiesRashi}
                        alt=""
                        className="w-[150px] h-[150px] rounded-[75px] "
                      />
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="text-white font-bold text-3xl">
                        Aries (Mesh)
                      </div>
                      <div className="text-white text-sm font-semibold">
                        Sun Jun 13 2004, 17:00:00
                      </div>
                    </div>
                    <div className="flex flex-row gap-2 border border-[#41403F] px-3 py-1 text-sm rounded-xl  items-center w-fit">
                      <span className="text-[#DEDE00]">
                        <TiWeatherSunny />
                      </span>{" "}
                      <span className="text-white">Sun Sign </span>{" "}
                      <span className="text-[#818080]">Cancer (karkat)</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
