import React from "react";
import { FaArrowRightToBracket } from "react-icons/fa6";
import AstrologersImage from "./../../assets/homeimage.webp";
type Props = {
  title: string;
  desscription: string;
  onbuttonclick: () => void;
};
const AstrologyCard = ({ title, desscription, onbuttonclick }: Props) => {
  return (
    <div className=" flex flex-col justify-center items-center w-full lg:py-4">
      <div className="grid lg:grid-cols-2 gap-5  px-10 py-10 border-[1px] border-[#33302F] rounded-xl lg:w-[60%]">
        <div className="flex flex-col justify-center">
          <img
            src={AstrologersImage}
            alt=""
            className="w-[100%] h-[300px] rounded-xl"
          />
        </div>
        <div className="flex flex-col gap-10">
          <div className="text-white font-medium text-[30px] ">{title}</div>
          <div className="text-white  text-[20px] "> {desscription}</div>
          <div className="text-white font-medium text-[30px] ">
            Try AI Astrologer now
          </div>
          <div
            className="bg-[#FF5959] rounded-2xl px-5  py-1 font-semibold w-fit flex flex-row items-center gap-5"
            onClick={onbuttonclick}
          >
            Get Started <FaArrowRightToBracket />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AstrologyCard;
