import React from "react";
import FaqCard from "../../../components/common/FaqCard";
import AstrologyCard from "../../../components/common/AstrologyCards";

const Faq = () => {
  const faqData = [
    {
      title: "What is Kundli GPT?",
      description:
        "Kundli GPT is a platform that leverages AI to provide personalized astrological insights based on Vedic astrology.Kundli GPT is a platform that leverages AI to provide personalized astrological insights based on Vedic astrology.",
    },
    {
      title: "How does Kundli GPT ensure accuracy?",
      description:
        "We combine ancient Vedic astrology principles with advanced AI algorithms to offer precise and actionable insights.Kundli GPT is a platform that leverages AI to provide personalized astrological insights based on Vedic astrology.",
    },
    {
      title: "Is my data secure with Kundli GPT?",
      description:
        "Yes, we prioritize your privacy and ensure that all data is encrypted and securely stored.",
    },
    {
      title: "Can I use Kundli GPT for free?",
      description:
        "We offer both free and premium services. Premium features include advanced insights and detailed reports.",
    },
    {
      title: "What areas of life does Kundli GPT cover?",
      description:
        "We provide guidance on career, health, relationships, finances, and much more.",
    },
    {
      title: "How can I get started?",
      description:
        "Sign up on our website, enter your kundli details, and start exploring the insights tailored to you.",
    },
  ];

  return (
    <div className="w-full h-full text-white ">
      <div className="">
        {/* Hero Section */}
        <header className="relative py-16 text-center">
          <div className="absolute inset-0 bg-gradient-to-b from-[#FF5959]to-transparent opacity-40"></div>
          <h1 className="text-5xl font-extrabold text-[#FF5959]">
            Frequently Asked Questions
          </h1>
          <p className="mt-4 text-xl text-gray-300 max-w-3xl mx-auto">
            Everything you need to know about Kundli GPT. Explore answers to the
            most common queries and get started today.
          </p>
        </header>

        <div className="flex flex-col items-center justify-center">
          {/* FAQ Grid */}
          <section className="px-6 lg:px-32 py-12 w-[70%]">
            <div className="flex flex-col gap-5">
              {faqData.map((faq, index) => (
                <div key={index}>
                  <FaqCard title={faq.title} description={faq.description} />
                </div>
              ))}
            </div>
          </section>
        </div>

        {/* Call to Action */}
        <div className="py-16 text-center bg-[#131313] text-white">
          <h2 className="text-3xl font-bold">Have more questions?</h2>
          <p className="mt-4 text-lg">
            Our team is here to help you. Get in touch for personalized
            assistance!
          </p>
          <a
            href="#"
            className="mt-8 inline-block bg-white text-black hover:text-black-400 px-8 py-3 rounded-full font-semibold shadow-lg transform hover:scale-110 transition-all duration-300"
          >
            Contact Support
          </a>
        </div>
        <div className="py-10">
          <AstrologyCard
            onbuttonclick={() => {}}
            title={"Why wait ?"}
            desscription={
              "Get your free AI Astrology reading with Vedic AstroGPT in just 30 seconds. Unlock your Vedic Birth Chart and start your journey with AI astrologer free."
            }
          />
        </div>
        {/* <div>
          <Footer />
        </div> */}
      </div>
    </div>
  );
};

export default Faq;
