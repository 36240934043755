import React from "react";
import { PiStarFour } from "react-icons/pi";
import image from "./../../../../assets/homeimage2.webp";
type Props = {};

const HomeCard3 = (props: Props) => {
  return (
    <div>
      <div>
        <h1 className="text-3xl md:text-5xl text-center md:text-left text-white font-bold text-gradient py-1 font-mono">
          AI Astrologers
        </h1>
        <p className="font-thin lg:text-start text-center">
          Meet our AI Astrologers
        </p>
      </div>
      <div className="grid lg:grid-cols-2 gap-4 flex-col items-center justify-center lg:pt-10">
        <div className="flex flex-col items-end justify-end lg:pr-20 shadow-xl">
          <img src={image} alt="#" className="lg:w-[50%] " />
        </div>
        <div className="flex flex-col justify-center">
          <h1 className="text-gradient text-4xl font-medium">Pawan Paudel</h1>
          <p className="text-primary">
            AI Astrologer{" "}
            <span className="py-1 px-3 text-xs font-bold font-mono bg-gray-700 rounded-full">
              Generic
            </span>
          </p>
          <div className="text-white py-10 flex flex-col gap-4" id="pricing">
            <ul className="flex flex-col gap-4">
              <li className="flex gap-2 text-gray-300">
                <span>
                  <PiStarFour />
                </span>
                Insightful Astrological Guidance
              </li>
              <li className="flex gap-2 text-gray-300">
                <span>
                  <PiStarFour />
                </span>
                Answers with astrological interpretations
              </li>
              <li className="flex gap-2 text-gray-300">
                <span>
                  <PiStarFour />
                </span>
                Current Time, Love, Education Careers and all your general
                astrological queries
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeCard3;
