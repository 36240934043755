import React from "react";

type Props = {
  title: string;
  description: string;
};

const FaqCard = ({ title, description }: Props) => {
  return (
    <div>
      {" "}
      <div className="w-full h-full">
        <div className="bg-[#131313] p-6 rounded-lg shadow-lg">
          <h3 className="text-2xl font-semibold mb-4">{title}</h3>
          <p className="text-gray-300 text-sm leading-relaxed">{description}</p>
          <a
            href="#"
            className="inline-block mt-4 text-sm text-[#FF5959] hover:underline"
          >
            Read more about this topic ↗
          </a>
        </div>
      </div>
    </div>
  );
};

export default FaqCard;
