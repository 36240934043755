import React, { useState } from "react";
import youngerAstrologer from "./../../../assets/homeimage2.webp";
import QuestionCard from "../../../components/common/QuestionCard";
import PriceCard from "../../../components/common/PricingCard";
import PaymentCard from "../../../components/common/PaymentCard";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { GoChevronDown } from "react-icons/go";
import { LuCrown } from "react-icons/lu";
import { BsChat } from "react-icons/bs";
import { categoryData } from "./categoryData";

const Ask = () => {
  const [openPaymentCard, setOpenPaymentCard] = useState(false);
  const QuestionData = [
    {
      id: 1,
      question: "What are my strngth and Weakness?",
      category: "Personality",
    },
    {
      id: 2,
      question: "What Type Of Job Suit Me  the best?",
      category: "Carrer",
    },
    {
      id: 3,
      question: "Tell Me about my relationship?",
      category: "Love",
    },
    {
      id: 4,
      question: "What do i value more in love and relationships?",
      category: "Love",
    },
    {
      id: 5,
      question: "How is my current time going?",
      category: "Current",
    },
    {
      id: 6,
      question: "Tell Me about my Personality",
      category: "Personality",
    },
  ];
  return (
    <div className="relative h-screen overflow-auto p-5">
      <div className="absolute inset-0 askPageBackground opacity-20 z-0 ">
        {" "}
      </div>

      <div className="flex flex-col gap-10 relative z-10">
        <div className="flex flex-row gap-3">
          <div className=" text-white">
            <img
              src={youngerAstrologer}
              alt=""
              className="w-[50px] h-[50px] rounded-3xl border border-[#41403F]"
            />
          </div>
          <div>
            <div className=" text-white font-semibold text-xl">
              Pawan Paudel
            </div>
            <div className=" text-white text-sm">AI Astrologer</div>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <div className=" text-white font-medium text-3xl">Hello Ajay,</div>
          <div className=" text-white text-md">
            Here are some questions you can ask to get started.
          </div>
        </div>
        <div className="grid xl:grid-cols-2 gap-5">
          {QuestionData?.map((question) => {
            return (
              <QuestionCard
                questionData={question}
                onCardClick={() => {
                  setOpenPaymentCard(true);
                }}
              />
            );
          })}
        </div>
        <div className="flex flex-col gap-5 py-10">
          {categoryData?.map((category) => {
            return (
              <div>
                <Accordion
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: "40px",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<GoChevronDown color="white" />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                      ":hover": { backgroundColor: "#FF5959", color: "white" },
                      borderRadius: "40px",
                      fontWeight: "medium",
                      color: "#FF5959",
                    }}
                  >
                    <div className="flex gap-3 items-center">
                      {/* <{category?.}/> */}
                      <span>{<category.catIcon />} </span>
                      {category?.category}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="flex flex-col gap-5">
                      {category?.subCategory?.map((subCategory) => {
                        return (
                          <div>
                            <Accordion
                              sx={{
                                backgroundColor: "transparent",
                                borderRadius: "40px",
                              }}
                            >
                              <AccordionSummary
                                expandIcon={<GoChevronDown color="white" />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                sx={{
                                  ":hover": {
                                    backgroundColor: "#FF5959",
                                    color: "white",
                                  },
                                  borderRadius: "40px",
                                  fontWeight: "medium",
                                  color: "#FF5959",
                                }}
                              >
                                <div className="flex gap-3 items-center">
                                  <div className="w-[30px] h-[30px] items-center border border-[#41403F] rounded-[15px]"></div>
                                  <div className="flex gap-3 items-center">
                                    {/* <{category?.}/> */}

                                    {subCategory?.subCategoryTitle}
                                  </div>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="grid md:grid-cols-2 xl:grid-cols-2  gap-5">
                                  {subCategory?.questionData?.map(
                                    (question) => {
                                      return (
                                        <div className="border border-[#41403F] p-5  rounded-xl hover:bg-[#FF5959] text-white  relative">
                                          <div className="w-[30px] h-[30px] bg-[#8D5421] content-center absolute right-0 top-[-3px] justify-items-center rounded-[20px] text-[18px] text-[#F6BE5E]">
                                            <LuCrown />
                                          </div>
                                          <div className="flex flex-col gap-5">
                                            <div className="">{<BsChat />}</div>
                                            <div className=" text-xl">
                                              {question?.question}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        );
                      })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })}
        </div>
        <div>
          <PriceCard />
        </div>
      </div>

      {openPaymentCard && (
        <PaymentCard
          onClose={() => {
            setOpenPaymentCard(false);
          }}
        />
      )}
    </div>
  );
};

export default Ask;
