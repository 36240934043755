import React from "react";
import { BsChat } from "react-icons/bs";
import { MdOutlineAddReaction } from "react-icons/md";
import { LuCrown } from "react-icons/lu";
import { FaBriefcase, FaGlobe, FaHeart } from "react-icons/fa";
type Props = {
  questionData: any;
  onCardClick: () => void;
};
const categoryIcons: Record<string, JSX.Element> = {
  personality: <MdOutlineAddReaction />,
  love: <FaHeart />,
  career: <FaBriefcase />,
  current: <FaGlobe />,
};
const QuestionCard = ({ questionData, onCardClick }: Props) => {
  return (
    <div
      className="border border-[#41403F] p-5  rounded-xl hover:bg-[#FF5959] text-white relative"
      onClick={onCardClick}
    >
      <div className="w-[30px] h-[30px] bg-[#8D5421] content-center absolute right-0 top-[-3px] justify-items-center rounded-[20px] text-[18px] text-[#FF5959]">
        <LuCrown />
      </div>
      <div className="flex flex-col gap-5">
        <div className="">{<BsChat />}</div>
        <div className=" text-xl">{questionData?.question}</div>
        <div className="flex flex-row gap-1 items-center w-fit bg-black py-1 px-3 rounded-2xl text-white ">
          {categoryIcons[questionData?.category?.toLowerCase()]}{" "}
          {questionData?.category}
        </div>
      </div>
    </div>
  );
};

export default QuestionCard;
