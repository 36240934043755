import React from "react";
import MainCard from "./component/MainCard";
import HomeCard2 from "./component/HomeCard2";
import HomeCard3 from "./component/HomeCard3";
import Pricing from "../pricing/Pricing";

import HomeFaq from "./component/HomeFaq";
import AstrologyCard from "../../../components/common/AstrologyCards";
import UserWord from "./component/UserWord";

type Props = {};

const Home = (props: Props) => {
  return (
    <div className="w-full h-full">
      <div className="items-center flex flex-col ">
        <div className="container ">
          <div className="flex flex-col gap-10">
            <MainCard />
            <HomeCard2 />
            <HomeCard3 />
            <Pricing />
            <UserWord />
            <AstrologyCard
              onbuttonclick={() => {}}
              title={"Why wait ?"}
              desscription={
                "Get your free AI Astrology reading with Vedic AstroGPT in just 30 seconds. Unlock your Vedic Birth Chart and start your journey with AI astrologer free."
              }
            />
            <HomeFaq />
          </div>
        </div>
      </div>
      {/* <div>
        <Footer />
      </div> */}
    </div>
  );
};

export default Home;
