import React from "react";
import astroCircle from "./../../../assets/astro_circle.png";
import { FcGoogle } from "react-icons/fc";
import { useDispatch } from "react-redux";
import { setToken } from "../../../slices/userSlice";
type Props = {};

const Login = (props: Props) => {
  const dispatch = useDispatch();
  return (
    <div>
      {" "}
      <div className="min-h-screen bg-black flex justify-center items-center text-white">
        <div className=" max-w-2xl w-full">
          <div className="grid grid-cols-2">
            <div className="">
              <img src={astroCircle} alt="" />
            </div>
            <div className="mt-[25px] flex flex-col gap-2">
              <h1 className="text-3xl font-semibold">
                Discover the empowering guidance{" "}
                <span className="text-[#FF5959]">Vedic Astrology</span> holds
                for your life
              </h1>
              <p className="text-lg ">Let's start by signing in.</p>
            </div>
          </div>
          <button
            className="border text-white py-4 px-6 rounded-xl text-[18px] flex gap-2 justify-center items-center w-full font-medium"
            onClick={() => dispatch(setToken("test001"))}
          >
            <FcGoogle />
            Continue With Google
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
