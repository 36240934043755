import React from "react";
import UserCard from "../../../../components/common/UserCard";

type Props = {};

const UserWord = (props: Props) => {
  const userData = [
    {
      name: "Aayush Raghuvanshi",
      role: "Student",
      avatar: "https://via.placeholder.com/60",
      rating: 5,
      review:
        "Accurate analysis about my love life, need for financial stability, my emotional sensitivity, sense of independence, and intellectual taste. Answers also gave me my future dasha periods which will be positive for me with detailed analysis of what will be the circumstances in those periods in the near future.",
    },
    {
      name: "Priya Sharma",
      role: "Entrepreneur",
      avatar: "https://via.placeholder.com/60",
      rating: 4.8,
      review:
        "The consultation was insightful and provided valuable guidance on my career choices. The astrologer explained things in detail, and their predictions about my professional growth seem accurate so far.",
    },
    {
      name: "Rahul Verma",
      role: "Software Engineer",
      avatar: "https://via.placeholder.com/60",
      rating: 4.5,
      review:
        "The analysis was accurate and helped me navigate through tough decisions in my personal and professional life. Highly recommend their services!",
    },
    {
      name: "Sanya Kapoor",
      role: "Artist",
      avatar: "https://via.placeholder.com/60",
      rating: 4.7,
      review:
        "The insights provided were incredibly accurate and helped me understand the challenges in my life. The astrologer’s advice about creative pursuits was spot on.",
    },
    {
      name: "Amit Singh",
      role: "Doctor",
      avatar: "https://via.placeholder.com/60",
      rating: 5,
      review:
        "I was amazed by the level of detail in the reading. It gave me clarity on various aspects of my life, including health and family. Highly professional service.",
    },
    {
      name: "Neha Gupta",
      role: "Teacher",
      avatar: "https://via.placeholder.com/60",
      rating: 4.9,
      review:
        "Their guidance helped me better understand my life's purpose and make informed decisions. The predictions have been surprisingly accurate so far.",
    },
  ];
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {userData?.map((user: any) => {
          return (
            <div className="">
              <UserCard
                name={user?.name}
                role={user?.role}
                avatar={user?.avatar}
                rating={user?.rating}
                review={user?.review}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UserWord;
