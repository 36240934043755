import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/unauthenticatedPages/home/Home";
import { Provider } from "react-redux";
import { store } from "./slices/store";
import Pricing from "./pages/unauthenticatedPages/pricing/Pricing";
import AboutUs from "./pages/unauthenticatedPages/aboutUs/AboutUs";
import Faq from "./pages/unauthenticatedPages/faq/Faq";
import HowDoesItWork from "./pages/unauthenticatedPages/howDoesItWork/HowDoesItWork";
import Sidebar from "./components/navigations/Sidebar";
import Ask from "./pages/authenticatedPages/ask/Ask";
import History from "./pages/authenticatedPages/history/History";
import Profile from "./pages/authenticatedPages/profile/Profile";
import Login from "./pages/auth/login/Login";
import BirthDetail from "./pages/authenticatedPages/profile/birthDetail/BirthDetail";
import Navbar from "./components/navigations/Navbar";

const App: React.FC = () => {
  const navLinks = [
    {
      label: "Pricing",
      path: "/pricing",
    },
    // { label: "Free Vedic Birth Charts", path: "/vedic_birth" },
    { label: "About-us", path: "/about_us" },

    { label: "How does it work ?", path: "/how-vedic-astro-works" },
    {
      label: "FAQ",
      path: "/faq",
      // dropdownLinks: [
      //   { label: "General FAQ's", path: "/education/math" },
      //   { label: "Vedic astro FAQ's ", path: "/education/science" },
      // ],
    },
  ];

  const logoPath = "/logo.webp";

  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<Navbar logo={logoPath} links={navLinks} />}>
            <Route index element={<Home />} />
            <Route path="pricing" element={<Pricing />} />
            {/* <Route path="vedic_birth" element={<VedicBirth />} /> */}
            <Route path="about_us" element={<AboutUs />} />
            <Route path="faq" element={<Faq />} />
            <Route path="how-vedic-astro-works" element={<HowDoesItWork />} />
          </Route>
          <Route path="/login" element={<Sidebar logo={logoPath} />}>
            <Route index element={<Login />} />
          </Route>
        </Routes>
        <Routes>
          <Route element={<Sidebar logo={logoPath} />}>
            <Route path="/ask" element={<Ask />} />
            <Route path="/history" element={<History />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/birth_detail" element={<BirthDetail />} />
          </Route>
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;
