import { Rating } from "@mui/material";
import React from "react";

type Props = {
  name: string;
  role: string;
  avatar: string;
  rating: number;
  review: string;
};

const UserCard = ({ name, role, avatar, rating, review }: Props) => {
  return (
    <div className="h-full w-full">
      <div className="bg-[#131313] p-6 rounded-lg shadow-lg flex flex-col justify-between h-full">
        <div className="flex items-center space-x-4">
          <img
            src={avatar}
            alt="User"
            className="w-12 h-12 rounded-full object-cover"
          />
          <div>
            <h2 className="text-xl font-semibold">{name}</h2>
            <p className="text-sm text-gray-400">{role}</p>
          </div>
        </div>
        <div className="mt-4 flex-grow">
          <div className="flex items-center">
            <Rating name="size-small" defaultValue={5} size="small" />
            <span className="ml-2 text-sm text-gray-400">{rating}</span>
          </div>
          <p className="mt-4 text-gray-300 text-sm leading-relaxed">{review}</p>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
