import React from "react";
import { RiFlashlightLine } from "react-icons/ri";

const PriceData = [
  {
    id: 1,
    credit: 1,
    indianPrice: 49,
    outsideIndiaPrice: 1,
  },
  {
    id: 2,
    credit: 7,
    indianPrice: 49,
    outsideIndiaPrice: 5,
  },
  {
    id: 3,
    credit: 15,
    indianPrice: 399,
    outsideIndiaPrice: 10,
  },
  {
    id: 1,
    credit: 1,
    indianPrice: 999,
    outsideIndiaPrice: 20,
  },
];
const PriceCard = () => {
  return (
    <div className="grid md:grid-cols-2 xl:grid-cols-4  gap-5">
      {PriceData?.map((price) => {
        return (
          <div className="bg-[#212121] p-5 rounded-xl flex flex-col gap-3">
            <div>
              <div className="flex flex-row font-bold items-center text-[25px] text-white gap-2">
                <RiFlashlightLine /> {price?.credit}
              </div>
              <div className="text-[18px] text-white">Question Credit</div>
            </div>
            <div className="flex flex-row gap-3">
              <div className="flex flex-col gap-1 bg-[#131313] px-4 py-1 rounded-xl w-fit">
                <div className="text-[25px] font-medium  bg-gradient-to-r from-[#f0e9e3] to-[#fb7373] bg-clip-text text-transparent">
                  {" "}
                  RS. {price?.indianPrice}
                </div>
                <div className="text-[14px] text-white">(Inside India)</div>
              </div>
              <div className="flex flex-col gap-1 bg-[#131313] px-4 py-1 rounded-xl w-fit">
                <div className="text-[25px] font-medium  bg-gradient-to-r from-[#f0e9e3] to-[#FF5959] bg-clip-text text-transparent">
                  {" "}
                  $ {price?.outsideIndiaPrice}
                </div>
                <div className="text-[14px] text-white">(Outside India)</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PriceCard;
