import AstrologyCard from "../../../components/common/AstrologyCards";
import BlogCard from "../../../components/common/BlogCard";
import AstrologyImage from "./../../../assets/blogImage.avif";
const HowDoesItWork = () => {
  const astrolgyBlogData = [
    {
      id: 1,
      title: "Kundli GPT vs Vedic AstroGPT: A Comprehensive Comparison ",
      date: "2022-05-20",
      blogImage: AstrologyImage,
      description: "Kundli GPT vs Vedic AstroGPT: A Comprehensive Comparison",
    },
    {
      id: 2,
      title: "Kundli GPT vs Vedic AstroGPT: A Comprehensive Comparison",
      date: "2022-05-20",
      blogImage: AstrologyImage,
      description: "Kundli GPT vs Vedic AstroGPT: A Comprehensive Comparison ",
    },
    {
      id: 3,
      title: "Kundli GPT vs Vedic AstroGPT: A Comprehensive Comparison",
      date: "2022-05-20",
      blogImage: AstrologyImage,
      description: "Kundli GPT vs Vedic AstroGPT: A Comprehensive Comparison",
    },
    {
      id: 4,
      title:
        "Kundli GPT vs Vedic AstroGPT: A Comprehensive Comparison Kundli GPT vs ",
      date: "2022-05-20",
      blogImage: AstrologyImage,
      description:
        "Kundli GPT vs Vedic AstroGPT: A Comprehensive Comparison Kundli GPT vs Vedic AstroGPT: A Comprehensive Comparison",
    },
  ];
  return (
    <div>
      <div className="flex flex-col items-center justify-center">
        <div className=" bg-black text-white lg:p-8 font-sans container lg:w-[60%] p-4">
          {/* Header Section */}
          <div className="text-center">
            <h1 className="text-4xl font-bold mb-4">
              How Vedic AstroGPT works?
            </h1>
            <button className="px-4 py-1 bg-gray-800 text-sm text-white rounded-full border border-gray-600 text-start">
              System
            </button>
          </div>

          {/* Content Section */}
          <div className="mt-8 space-y-6">
            <p>
              In this blog, we will try to explain how Vedic AstroGPT works to
              provide you with astrological insights based on your questions.
              We'll break down the process into easy-to-understand steps.
            </p>
            <ol className="list-decimal ml-6 space-y-4">
              <li>
                <strong className="text-[18px]">
                  Creating Your Cosmic Map:
                </strong>{" "}
                Our journey begins with the creation of your natal birth chart
                (or Kundali). This chart is like a cosmic map, showing where the
                stars and planets were located at the exact moment you were
                born. To create this map, we need your date of birth, time, and
                place of birth.
              </li>
              <li>
                <strong className="text-[18px]">Question Time:</strong> When you
                ask a question: - If it’s something that astrology can’t answer,
                we’ll let you know right away. If it’s a question that astrology
                can delve into, our system chooses the best astrological method
                to find the answer.
              </li>
              <li>
                <strong className="text-[18px]">
                  Diving into the Astrological Ocean:
                </strong>{" "}
                With the chosen method, we explore your birth chart and the
                special planetary periods known as Dasha periods to gather
                insights regarding your question.
              </li>
              <li>
                <strong className="text-[18px]">Crafting Your Answer:</strong> A
                Large Language Model (LLM) then takes these astrological
                interpretations and crafts a simple, easy-to-understand answer.
                We also share the detailed astrological analysis with you, so
                you can see the thought process behind the answer if you wish.
              </li>
            </ol>
            <p>
              The entire process is grounded in revered astrological texts and
              references including, but not limited to, Brihat Parashara Hora
              Shastra by Maharishi Parashara, Jaimini Upadesha Sutras by
              Maharishi Jaimini, and Brihat Jataka by Varahamihira.
            </p>
            <p>
              The process behind Vedic AstroGPT is a blend of ancient
              astrological wisdom and modern AI technology, working together to
              provide answers to your questions. By understanding how Vedic
              AstroGPT works, you can better appreciate the insights it offers
              and how they are derived. So, feel free to ask your questions and
              explore the astrological insights waiting for you on Vedic
              AstroGPT.
            </p>
          </div>
        </div>
        <div className="bg-black p-5">
          <div className="justify-items-center items-center flex flex-col gap-12">
            <AstrologyCard
              title={"Why wait?"}
              desscription={
                "Get your free AI Astrology reading with Vedic AstroGPT in just 30 seconds. Unlock your Vedic Birth Chart and start your journey with AI astrologer free."
              }
              onbuttonclick={() => {}}
            />
            <div className="flex flex-col gap-6 justify-center items-center container">
              <div className="text-white font-bold text-2xl p-5">
                More Blogs
              </div>
              <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-5 justify-items-center lg:w-[70%]">
                {astrolgyBlogData?.map((blog: any) => {
                  return (
                    <div>
                      <BlogCard
                        blogImage={blog?.blogImage}
                        title={blog?.title}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default HowDoesItWork;
